/**
 * @returns components buttons

*/
import { FaReply, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

// Components
import { Title } from '@Components/Atomos/Titles';

// Styles
import style from '@Sass/components/buttons.module.scss';

export const ButtonClose = ({ setModalSalir, titlePage }) => {
  return (
    <div className={style.buttonCloseContaier}>
      <div></div>
      <div>
        <Title title={titlePage} />
      </div>
      <div>
        <div className={style.buttonClose}>
          <button className={style.btnClose} onClick={() => setModalSalir(true)}>
            Salir <FaTimes className={style.iconClose} />
          </button>
        </div>
      </div>
    </div>
  );
};

export const ButtonGoBack = ({ setPage, titlePage, page }) => {
  return (
    <div className={style.buttonCloseContaier}>
      <div></div>
      <div>
        <Title title={titlePage} />
      </div>
      <div>
        <div className={style.buttonClose}>
          <button className={style.btnClose} onClick={() => setPage(page)}>
            <FaReply className={style.iconClose} />
          </button>
        </div>
      </div>
    </div>
  );
};

export const ButtonOut = (props) => {
  const { setModal } = props;
  return (
    <div className={style.Buttons}>
      <div className={style.buttonClose}>
        <button className={style.btnClose} onClick={() => setModal(true)}>
          Salir <FaTimes className={style.iconClose} />
        </button>
      </div>
    </div>
  );
};

export const SaberMas = ({ data }) => {
  const location = useLocation();
  return (
    <p className={style.saberMas}>
      <Link
        to={{
          pathname: '/saber-mas',
          state: { data },
          from: location.pathname,
        }}
      >
        {data?.seccion_de_apoyo ? data?.seccion_de_apoyo : 'Saber más'}
      </Link>
    </p>
  );
};
