import { useEffect } from 'react';

// Components
import { ParagraphPlanet } from '@Components/Atomos/Titles';

// Images
import astronout from '@Assets/images/astro_buyer.png';

// Styles
import style from '@Sass/pages/venus.module.scss';

import { FaPlusCircle } from 'react-icons/fa';

/** VENUS BUYER INFO
 * page 8
 */
export const BuyerInfo = ({ setPage, setTitle }) => {
  useEffect(() => {
    setTitle('BUYER PERSONA');
  }, []);

  return (
    <div className={style.buyerInfo}>
      <section className={style.venusQuestions}>
        <ParagraphPlanet text="El buyer persona es una representación de tu cliente/s ideal/es, basada en datos demográficos, comportamientos, necesidades y motivaciones. Es una herramienta de investigación, análisis y reflexión que te ayuda a entender e identificar mejor a tu público objetivo, sus características, estilo de vida, puntos de dolor entre otros, así cómo tener mayor claridad de sus necesidades y cómo puedes ayudar." />
        <br></br>





        <button type="button" className={style.btnBuyer} onClick={() => setPage(9)}>
          CONTINUAR
        </button>
        <span className={style.addbuyer} onClick={() => setPage(8)}>
          <FaPlusCircle className={style.icon} /> <b>Agregar más Buyer Persona</b>
        </span>
      </section>
      <div className={style.astronaut}>
        <img src={astronout} alt="astronaut" />
      </div>
    </div>
  );
};
