import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

// Store
import { marteStore } from '@Store/marte';

// Hook
import { valPackage } from '@Hooks/useValidatePlanet';

// Styles
import style from '@Components/Planets/planets.module.scss';
import lines from '@Components/Planets/lines.module.scss';
import buttons from '@Sass/components/buttons.module.scss';

// Images
import _tank from '@Assets/images/tanque.png';
import _lock from '@Assets/images/lock.png';
import marte from '@Assets/images/planets/marte.svg';

export const Marte = ({ marteInfo }) => {
  const [skills, setSkills] = useState([
    {
      skill: '',
    },
    {
      skill: '',
    },
  ]);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('MARTE');
  const { getMarte, dataMarte } = marteStore(
    (state) => ({
      getMarte: state.getMarte,
      dataMarte: state.dataMarte,
    }),
    shallow
  );

  const { validateProject } = valPackage();

  useEffect(() => {
    if (marteInfo.titulo) {
      setSkills(marteInfo.skills);
      setDescription(marteInfo.descripcion);
      setTitle(marteInfo.titulo);
    }
  }, [marteInfo]);
/* 
  useEffect(() => {
    console.log(dataMarte);
  }, [dataMarte]); */

  return (
    <>
      <div>
        <div className={style.cardItem}>
          <div className={style.card_name_tankItem}>
            <h2>{title}</h2>
            <div className={style.card_tank_value}>
              <label>x5</label>
              <img src={_tank} alt="tank" />
            </div>
          </div>
          <div className={`${style.planetImage_content} ${lines.lines_marte} `}>
            <div className={lines.lines_marte_1}>
              <p>{skills[0].skill}</p>
            </div>
            <div className={lines.lines_marte_2}>
              <p>{skills[1].skill}</p>
            </div>
            {getMarte().lockedPlanet === 'desbloqueado' ? (
              <></>
            ) : (
              <img src={_lock} alt="lock" className={style.imgLock} />
            )}
            <img src={marte} alt="planet" className={`${style.planetImage} ${style.marte}`} />
          </div>
          <div className={style.planetInfo}>
            <p>{description}</p>
          </div>
          <div className={style.planetButton_content}>
            {getMarte().id ? (
              <button
                className={`${
                  dataMarte.complete === 1
                    ? buttons.buttonPlanetCompleted
                    : dataMarte.complete === 2
                    ? buttons.buttonPlanetIncomplete
                    : dataMarte.complete === 0
                    ? buttons.buttonPlanet
                    : buttons.buttonPlanet
                }`}
                onClick={() => validateProject('marte', 5)}
              >
                {dataMarte.label}
              </button>
            ) : (
              <button className={style.moon_launchBtn} onClick={() => validateProject('marte', 5)}>
                ACCEDER
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
