import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
// Store
import { lunaStore } from '@Store/luna';

// Styles
import style from '@Components/Planets/planets.module.scss';
import lines from '@Components/Planets/lines.module.scss';
import buttons from '@Sass/components/buttons.module.scss';

// Images
import luna from '@Assets/images/planets/luna.svg';

export const Luna = ({ lunaInfo }) => {
  const [skills, setSkills] = useState([
    {
      skill: '',
    },
    {
      skill: '',
    },
    {
      skill: '',
    },
  ]);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('LUNA');

  const { getLuna, dataLuna } = lunaStore(
    (state) => ({
      getLuna: state.getLuna,
      dataLuna: state.dataLuna,
    }),
    shallow
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (lunaInfo.titulo) {
      setSkills(lunaInfo.skills);
      setDescription(lunaInfo.descripcion);
      setTitle(lunaInfo.titulo);
    }
  }, [lunaInfo]);

  return (
    <div id="launch-moon">
      <div className={style.cardItem}>
        <div className={style.card_name_tankItem}>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        </div>
        <div className={`${style.planetImage_content} ${lines.lines_luna} `}>
          <div className={lines.lines_luna_1}>
            <p dangerouslySetInnerHTML={{ __html: skills[0].skill }}></p>
          </div>
          <div className={lines.lines_luna_2}>
            <p dangerouslySetInnerHTML={{ __html: skills[1].skill }}></p>
          </div>
          <div className={lines.lines_luna_3}>
            <p dangerouslySetInnerHTML={{ __html: skills[2].skill }}></p>
          </div>
          <img src={luna} alt="planet" className={`${style.planetImage}`} />
        </div>
        <div className={style.planetInfo}>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
        <div className={style.planetButton_content}>
          {getLuna().id ? (
            <button
              className={`${
                dataLuna?.complete === 1
                  ? buttons.buttonPlanetCompleted
                  : dataLuna?.complete === 2
                  ? buttons.buttonPlanetIncomplete
                  : dataLuna?.complete === 0
                  ? buttons.buttonPlanet
                  : buttons.buttonPlanet
              }`}
              onClick={() => history.push({ pathname: '/launch', from: location })}
            >
              {dataLuna?.label}
            </button>
          ) : (
            <button
              className={style.moon_launchBtn}
              onClick={() => history.push({ pathname: '/launch', from: location })}
            >
              EMPIEZA AQUÍ
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
