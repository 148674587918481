import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import { FaUser, FaSuitcase, FaMapMarkerAlt } from 'react-icons/fa';

// Store
import { venusStore } from '@Store/venus';
import { lunaStore } from '@Store/luna';

// Hooks
import { useEventsVenus } from '@Hooks/useEventVenus';

// Components
import { ScrollToTop } from '@Components/UtilsComponents/ScrollTop';

import { ToolTipBackground } from '@Components/Atomos/Tooltips';

// Images
import profile from '@Assets/images/profile.png';

// Styles
import style from '@Sass/pages/venus.module.scss';

/** VENUS BUYER COMPLETE
 * page 7
 */
export const Buyer = ({ setModal, setTitle, texts, setMessage }) => {
  const { venusGetBuyerPersona, venusCreateBuyerPersona } = useEventsVenus();
  const { getLuna } = lunaStore(
    (state) => ({
      getLuna: state.getLuna,
    }),
    shallow
  );
  const { getVenus, setStateBuyer } = venusStore(
    (state) => ({
      getVenus: state.getVenus,
      setStateBuyer: state.setStateBuyer,
    }),
    shallow
  );
  const { register, handleSubmit } = useForm();
  const [buyer, setBuyer] = useState({});
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    setTitle(texts.titulo_de_la_vista);
  }, [texts]);

  useEffect(() => {
    setStateBuyer(buyer);
  }, [buyer]);

  useEffect(() => {
    handleGetBuyer();
    setActiveSection('GOALS');
  }, []);

  const handleGetBuyer = async () => {
    const res = await venusGetBuyerPersona();
    if (res.code === 0) {
      setBuyer({
        nombre: res.data?.nombre || '',
        frase: res.data?.frase || '',
        edad: res.data?.edad || '',
        ubicacion: res.data?.ubicacion || '',
        profesion: res.data?.profesion || '',
        background: res.data?.background || '',
        goals: res.data?.goals || '',
        motivations: res.data?.motivations || '',
        frustrations: res.data?.frustrations || '',
      });
    }
  };

  const onSubmit = async (data) => {
    const res = await venusCreateBuyerPersona(data);
    if (res.code === 0) {
      setMessage(
        `Haz completado <strong>VENUS</strong> de tu proyecto <strong>${getLuna().nombre}</strong>`
      );
      setModal(true);
    }
  };

  const handleMenu = (item) => {
    setActiveSection(item);
  };

  return (
    <section className={style.venusQuestions}>
      <ScrollToTop />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.buyer}>
          <div className={style.card1}>
            <div className={style.card1Content}>
              <div className={style.imageContent}>
                <img src={profile} alt="profile" />
              </div>
              <input
                {...register('nombre', { required: true })}
                type="text"
                placeholder="Nombre del buyer persona"
                className={style.inputBuyernanme}
                name="nombre"
                id="nombre"
                defaultValue={getVenus().buyer.nombre}
                onChange={(e) => setBuyer({ ...buyer, nombre: e.target.value })}
              />
              <input
                {...register('frase', { required: true })}
                type="text"
                placeholder="Frase favorita"
                className={style.inputFrase}
                defaultValue={getVenus().buyer.frase}
                onChange={(e) => setBuyer({ ...buyer, frase: e.target.value })}
              />
              <div className={style.iconsContent}>
                <div className={style.box}>
                  <FaUser className={style.icon} />
                  <input
                    {...register('edad', { required: true })}
                    type="number"
                    placeholder="Edad"
                    className={style.inputCard1}
                    defaultValue={getVenus().buyer.edad}
                    onChange={(e) => setBuyer({ ...buyer, edad: e.target.value })}
                  />
                </div>
                <div className={style.box}>
                  <FaMapMarkerAlt className={style.icon} />
                  <input
                    {...register('ubicacion', { required: true })}
                    type="text"
                    placeholder="Ubicación"
                    className={style.inputCard1}
                    defaultValue={getVenus().buyer.ubicacion}
                    onChange={(e) => setBuyer({ ...buyer, ubicacion: e.target.value })}
                  />
                </div>
                <div className={style.box}>
                  <FaSuitcase className={style.icon} />
                  <input
                    {...register('profesion', { required: true })}
                    type="text"
                    placeholder="Profesión"
                    className={style.inputCard1}
                    defaultValue={getVenus().buyer.profesion}
                    onChange={(e) => setBuyer({ ...buyer, profesion: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style.card2}>
            <ToolTipBackground text="Background" toottip={texts.background} />
            <textarea
              {...register('background', { required: true })}
              className={style.descripcion}
              name="background"
              rows="10"
              placeholder="Describe a tu buyer persona"
              defaultValue={getVenus().buyer.background}
              onChange={(e) => setBuyer({ ...buyer, background: e.target.value })}
            ></textarea>
          </div>

          <div className={style.card3}>
            <span className={style.options}>
              <div className={`${style.item}`}>
                <p
                  className={`${activeSection === 'GOALS' ? style.activeItem : ''}`}
                  onClick={() => handleMenu('GOALS')}
                >
                  {texts.goals}
                </p>
              </div>
              <div className={`${style.item}`}>
                <p
                  className={`${activeSection === 'MOTIVATIONS' ? style.activeItem : ''}`}
                  onClick={() => handleMenu('MOTIVATIONS')}
                >
                  {texts.motivations}
                </p>
              </div>
              <div className={`${style.item}`}>
                <p
                  className={`${activeSection === 'FRUSTATIONS' ? style.activeItem : ''}`}
                  onClick={() => handleMenu('FRUSTATIONS')}
                >
                  {texts.frustrations}
                </p>
              </div>
            </span>

            <div className={`${style.section} ${activeSection === 'GOALS' ? style.active : ''}`}>
              <p className={style.info}>{texts.instruccion_de_goals}</p>
              <textarea
                className={style.metas}
                name="background"
                rows="10"
                placeholder="Describe tus goals"
                defaultValue={getVenus().buyer.goals}
                onChange={(e) => setBuyer({ ...buyer, goals: e.target.value })}
                {...register('goals', { required: false })}
              ></textarea>
            </div>

            <div
              className={`${style.section} ${activeSection === 'MOTIVATIONS' ? style.active : ''}`}
            >
              <p className={style.info}>{texts.instruccion_de_motivations}</p>
              <textarea
                className={style.metas}
                name="background"
                rows="10"
                placeholder="Describe tus motivations"
                defaultValue={getVenus().buyer.motivations}
                onChange={(e) => setBuyer({ ...buyer, motivations: e.target.value })}
                {...register('motivations', { required: false })}
              ></textarea>
            </div>

            <div
              className={`${style.section} ${activeSection === 'FRUSTATIONS' ? style.active : ''}`}
            >
              <p className={style.info}>{texts.intruccion_de_frustrations}</p>
              <textarea
                className={style.metas}
                name="background"
                rows="10"
                placeholder="Describe tus frustations"
                defaultValue={getVenus().buyer.frustrations}
                onChange={(e) => setBuyer({ ...buyer, frustrations: e.target.value })}
                {...register('frustrations', { required: false })}
              ></textarea>
            </div>
          </div>

          <div className={style.btnContent}>
            <button type="submit" className={style.btnBuyer}>
              GUARDAR
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};
