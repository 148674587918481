/* eslint-disable camelcase */
import { useEffect } from 'react';

import { ScrollToTop } from '@Components/UtilsComponents/ScrollTop';
import { ParagraphPlanet } from '@Components/Atomos/Titles';

// Images
import astronout from '@Assets/images/astro_buyer.png';

// Styles
import jupiter from '@Sass/pages/jupiter.module.scss';

/* Page 7 */
export const BuyerInfo = ({ setPage, setTitle, texts }) => {
  useEffect(() => {
    setTitle(texts.titulo_de_la_vista);
  }, [texts]);
  return (
    <>
      <ScrollToTop />
      <section className={jupiter.buyerContainer}>
        <ParagraphPlanet text="Dignissim commodo at arcu mattis mi purus. Nibh nec, nunc eu, vulputate. Non pellentesque at odio lectus vitae tortor a pharetra ligula." />
        <br></br>
        <button type="button" className={jupiter.btnPlanet} onClick={() => setPage(8)}>
          CONTINUAR
        </button>
      </section>
      <div className={jupiter.astronaut}>
        <img src={astronout} alt="astronaut" />
      </div>
    </>
  );
};
