import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

// Store
import { uranoStore } from '@Store/urano';

// Hook
import { valPackage } from '@Hooks/useValidatePlanet';

// Styles
import style from '@Components/Planets/planets.module.scss';
import buttons from '@Sass/components/buttons.module.scss';
import lines from '@Components/Planets/lines.module.scss';

// Images
import _tank from '@Assets/images/tanque.png';
import _lock from '@Assets/images/lock.png';
import tierra from '@Assets/images/planets/urano.svg';

export const Urano = ({ uranoInfo }) => {
  const [skills, setSkills] = useState([
    {
      skill: '',
    },
    {
      skill: '',
    },
    {
      skill: '',
    },
  ]);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('URANO');

  // Store de mercurio
  const { dataUrano, setUrano, geUrano } = uranoStore(
    (state) => ({
      dataUrano: state.dataUrano,
      setUrano: state.setUrano,
      geUrano: state.geUrano,
    }),
    shallow
  );

  const { validateProject } = valPackage();

  useEffect(() => {
    if (uranoInfo.titulo) {
      setSkills(uranoInfo.skills);
      setDescription(uranoInfo.descripcion);
      setTitle(uranoInfo.titulo);
    }
  }, [uranoInfo]);

  return (
    <div>
      <div className={style.cardItem}>
        <div className={style.card_name_tankItem}>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className={style.card_tank_value}>
            <label>x3</label>
            <img src={_tank} alt="tank" />
          </div>
        </div>
        <div className={`${style.planetImage_content} ${lines.lines_urano} `}>
          <div className={lines.lines_urano_1}>
            <p dangerouslySetInnerHTML={{ __html: skills[0].skill }}></p>
          </div>
          <div className={lines.lines_urano_2}>
            <p dangerouslySetInnerHTML={{ __html: skills[1].skill }}></p>
          </div>
          <div className={lines.lines_urano_3}>
            <p dangerouslySetInnerHTML={{ __html: skills[2].skill }}></p>
          </div>
          {geUrano().lockedPlanet === 'desbloqueado' ? (
            <></>
          ) : (
            <img src={_lock} alt="lock" className={style.imgLock} />
          )}
          <img src={tierra} alt="planet" className={`${style.planetImage} ${style.urano}`} />
        </div>
        <div className={style.planetInfo}>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>

        <div className={style.planetButton_content}>
          {geUrano().id ? (
            <button
              className={`${
                dataUrano.complete === 1
                  ? buttons.buttonPlanetCompleted
                  : dataUrano.complete === 2
                  ? buttons.buttonPlanetIncomplete
                  : dataUrano.complete === 0
                  ? buttons.buttonPlanet
                  : buttons.buttonPlanet
              }`}
              onClick={() => validateProject('urano', 3)}
            >
              {dataUrano.label}
            </button>
          ) : (
            <button className={style.moon_launchBtn} onClick={() => validateProject('urano', 3)}>
              ACCEDER
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
