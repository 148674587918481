import { useEffect, useState } from 'react';

// STYLES
import style from '@Components/Planets/planets.module.scss';
import lines from '@Components/Planets/lines.module.scss';

// IMAGES
import _tank from '@Assets/images/tanque.png';
import _lock from '@Assets/images/lock.png';
import neptuno from '@Assets/images/planets/neptuno.svg';
import { valPackage } from '@Hooks/useValidatePlanet';

export const Neptuno = ({ neptunoInfo }) => {
  const [skills, setSkills] = useState([
    {
      skill: '',
    },
    {
      skill: '',
    },
    {
      skill: '',
    },
  ]);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('NEPTUNO');

  const { popup } = valPackage();

  useEffect(() => {
    if (neptunoInfo.titulo) {
      setSkills(neptunoInfo.skills);
      setDescription(neptunoInfo.descripcion);
      setTitle(neptunoInfo.titulo);
    }
  }, [neptunoInfo]);

  return (
    <div>
      <div className={style.cardItem}>
        <div className={style.card_name_tankItem}>
          <h2>{title}</h2>
          <div className={style.card_tank_value}>
            <label>x5</label>
            <img src={_tank} alt="tank" />
          </div>
        </div>
        <div className={`${style.planetImage_content} ${lines.lines_neptuno} `}>
          <div className={lines.lines_neptuno_1}>
            <p>{skills[0].skill}</p>
          </div>
          <div className={lines.lines_neptuno_2}>
            <p>{skills[1].skill}</p>
          </div>
          <div className={lines.lines_neptuno_3}>
            <p>{skills[2].skill}</p>
          </div>
          <img src={_lock} alt="lock" className={style.imgLock} />
          <img src={neptuno} alt="planet" className={`${style.planetImage} ${style.neptuno}`} />
        </div>
        <div className={style.planetInfo}>
          <p>{description}</p>
        </div>
        <div className={style.planetButton_content}>
          <button className={style.moon_launchBtn} onClick={() => popup()}>ACCEDER</button>
        </div>
      </div>
    </div>
  );
};
