import { Element } from 'react-scroll';

// Components
import Video from '@Components/Video/Video';
import Planets from '@Components/PlanetContent/PlanetContent';

const Home = () => {
  return (
    <section className="Home">
      <Video />
      <Element name="bio">
        <Planets />
      </Element>
    </section>
  );
};

export default Home;
